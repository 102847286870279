import React from "react";
import Button from "../elements/button";
import classNames from "classnames"
import WPImage from "../elements/wp-image";
import QuoteForm from "../partials/quote-form";
import Usps from "../partials/usps";
import {graphql, useStaticQuery} from "gatsby";
import AprText from "../partials/apr-text";

const HomeBanner = ({data}) => {

    const query = useStaticQuery(graphql`
        query {
            wordpressAcfOptions {
                options {
                    loan_title
                    feefo_image {
                        ...WordPressImage
                    }
                }
            }
        }
    `)

    return (
        <div className="section-container">
            <div
                className="gradient-blue-purple page-banner page-banner-lg section-content-image home-banner curve-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-6">
                            <div className="section-content-image-content entry-content">
                                <div class="section-dark">
                                    <h1 className="h4">Get Your Pre-Approved Personal Loan in Minutes</h1>
                                    <p className="lead">We save you time and effort by only showing you personalised
                                        loan
                                        deals that you are eligible for.</p>
                                    <Usps/>
                                </div>
                                <div class="box">
                                    <QuoteForm/>
                                    <AprText/>
                                </div>
                            </div>
                        </div>
                        <div className="col col-sm-6 col-lg-5 col-lg-offset-1">
                          <div className="section-content-image-image">
                              <WPImage img={data.image}/>
                          </div>
                          <div className="section-footer">
                            Feefo here
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner
