import React, {Component} from "react"
import {graphql} from "gatsby"
import Layout from "../layouts/index"
import PageBanner from "../components/section/page-banner";
import IconBlocks from "../components/section/icon-blocks";
import Quote from "../components/section/quote";
import Faqs from "../components/section/faqs";
import Content from "../components/section/content";
import ContentImage from "../components/section/content-image";
import Testimonials from "../components/section/testimonials";
import CategoryFaqs from "../components/section/category-faqs";
import Steps from "../components/section/steps";

class Page extends Component {

    getBannerData() {
        return {
            size: this.props.data.wordpressPage.acf.banner_size,
            bg_colour: this.props.data.wordpressPage.acf.banner_bg_colour,
            image: this.props.data.wordpressPage.acf.banner_image,
            title: this.props.data.wordpressPage.acf.banner_title,
            subtitle: this.props.data.wordpressPage.acf.banner_subtitle,
            text: this.props.data.wordpressPage.acf.banner_text,
            buttons: this.props.data.wordpressPage.acf.banner_buttons
        }
    }

    render() {

        return (
            <Layout location={this.props.location}>
                <PageBanner data={this.getBannerData()}/>
                {this.props.data.wordpressPage.acf.page_builder_page && this.props.data.wordpressPage.acf.page_builder_page.map(field => (
                    <>
                        {field.internal.type === 'WordPressAcf_content' && (
                            <Content data={field}/>
                        )}
                        {field.internal.type === 'WordPressAcf_content_image' && (
                            <ContentImage data={field}/>
                        )}
                        {field.internal.type === 'WordPressAcf_icon_blocks' && (
                            <IconBlocks data={field}/>
                        )}
                        {field.internal.type === 'WordPressAcf_loan_quote' && (
                            <Quote data={field} options={this.props.data.wordpressAcfOptions.options}/>
                        )}
                        {field.internal.type === 'WordPressAcf_faqs' && (
                            <Faqs data={field}/>
                        )}
                        {field.internal.type === 'WordPressAcf_faq_category' && (
                            <CategoryFaqs data={field}/>
                        )}
                        {field.internal.type === 'WordPressAcf_testimonials' && (
                            <Testimonials data={field}/>
                        )}
                        {field.internal.type === 'WordPressAcf_steps' && (
                            <Steps data={field} />
                        )}
                    </>
                ))}
            </Layout>
        )
    }
}

export default Page

export const pageQuery = graphql`
    fragment WordPressImage on wordpress__wp_media {
        alt_text
        localFile {
            publicURL
        }
    }
    query($id: String!) {
        wordpressPage(id: {eq: $id}) {
            title
            acf {
                banner_size
                banner_bg_colour
                banner_image {
                    ...WordPressImage
                }
                banner_title
                banner_subtitle
                banner_text
                banner_buttons {
                    text
                    link
                    type
                }
                page_builder_page {
                    ... on WordPressAcf_content {
                        id                    
                        internal { 
                            type
                        } 
                        content                   
                        width
                        align
                    }
                    ... on WordPressAcf_content_image {
                        id
                        internal { 
                            type
                        }                    
                        image {
                            ...WordPressImage         
                        }
                        image_position
                        content
                    }
                    ... on WordPressAcf_faq_category {
                        id
                        internal { 
                            type
                        }                    
                        title
                        image {
                            ...WordPressImage
                        }
                        text
                        faqs {
                            post_title
                            acf {
                                answer                             
                            }
                        }
                    }
                    ... on WordPressAcf_faqs {
                        id
                        internal { 
                            type
                        }                    
                        title
                        show
                        faqs {
                            post_title
                            acf {
                                answer
                                image {
                                    ...WordPressImage
                                }
                            }
                        }
                    }
                    ... on WordPressAcf_testimonials {
                        id
                        internal { 
                            type
                        }                    
                        title
                        show
                        testimonials {
                            post_title
                            acf {            
                                image {
                                    ...WordPressImage
                                }                                            
                                location
                                quote
                            }
                        }
                    }
                    ... on WordPressAcf_icon_blocks {
                        id
                        internal { 
                            type
                        }                    
                        title
                        icon_blocks {
                            icon {                                
                                ...WordPressImage              
                            }
                            title
                            text
                        }
                        section_background
                        section_curves
                        background_wave
                    }
                    ... on WordPressAcf_loan_quote {
                        id
                        internal { 
                            type
                        }                    
                        title
                    }
                    ... on WordPressAcf_steps {
                        id
                        internal { 
                            type
                        }                    
                        title
                        steps {
                            title
                            text
                            ticked
                            image {
                              ...WordPressImage
                            }                
                        }
                        section_background
                        section_curves
                        background_wave                        
                    }
                    ... on WordPressAcf_guide_categories {
                        id
                        internal {
                            type
                        }
                    }
                    ... on WordPressAcf_guides {
                        id
                        internal {
                            type
                        }
                    }
                }
            }
        }
        wordpressAcfOptions {
            options {
                compliance_text
                copyright_text
                favicon {
                    ...WordPressImage           
                }   
                feefo_image {
                    ...WordPressImage
                }      
                loan_title
                personalised_apr_text      
                privacy_text           
                usps {
                    usp
                }
            }
        }    
    }
`